<template>
  <v-layout justify-center align-center fill-height class="grey lighten-4">
    <v-card class="pa-16 card text-center w-100" elevation="18">
      <v-img src="@/assets/logo.svg" height="50" contain class="mb-10 mt-4" />
      <EditUser />
    </v-card>
  </v-layout>
</template>

<script>
import EditUser from '@/modules/user/EditUser'

export default {
  name: 'Register',
  components: {
    EditUser,
  },
}
</script>

<style lang="scss" scoped>
.card {
  max-width: 700px;
  box-shadow: -10px 10px 20px 0 rgba(30, 30, 30, 0.05);
}
</style>
