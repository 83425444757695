<template>
  <v-form class="form mx-auto" @submit.prevent="isNewUser ? createUser() : updateUser()">
    <v-row>
      <v-col cols="12" :sm="isNewUser ? '6' : '12'">
        <v-text-field
          :value="form.email"
          :label="$t('user.fields.email')"
          type="email" name="email" autocomplete="email"
          :error-messages="getFieldErrors('email')"
          disabled
        />
      </v-col>
      <v-col v-if="isNewUser" cols="12" sm="6">
        <v-text-field
          v-model="form.password" :label="$t('user.fields.password')"
          :error-messages="getFieldErrors('password')"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'" name="password" autocomplete="new-password"
          class="mb-6"
          @click:append="showPassword = !showPassword"
          @blur="$v.form.password.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="form.firstName" :label="$t('user.fields.firstName')"
          :error-messages="getFieldErrors('firstName')"
          type="text" name="given-name" autocomplete="name given-name"
          class="mb-6"
          @blur="$v.form.firstName.$touch()"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="form.lastName" :label="$t('user.fields.lastName')"
          :error-messages="getFieldErrors('lastName')"
          type="text" name="family-name" autocomplete="name family-name"
          class="mb-6"
          @blur="$v.form.lastName.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="d-flex"
        style="align-items: end;"
        cols="12" sm="6"
      >
        <v-row class="d-flex pa-2">
          <birthday-field
            v-model="form.birthdate"
            :error-messages="getFieldErrors('birthdate')"
            @blur="$v.form.birthdate.$touch()"
          />
        </v-row>
      </v-col>
      <v-col cols="12" sm="6">
        <v-autocomplete
          v-model="form.country"
          :items="Object.values(countries)"
          :label="$t('user.fields.country')"
          :error-messages="getFieldErrors('country')"
          @blur="$v.form.country.$touch()"
        />
      </v-col>
    </v-row>
    <v-btn type="submit" class="mt-10 mb-4" color="primary" block depressed v-text="isNewUser ? $t('user.register') : $t('common.save')" />
    <!-- <v-row v-if="!isNewUser">
      <v-col><v-btn class="mb-4" color="primary" block v-text="$t('user.changeEmail')" /></v-col>
      <v-col><v-btn class="mb-4" color="primary" block v-text="$t('user.changePassword')" /></v-col>
    </v-row> -->
  </v-form>
</template>

<script>
import { required, requiredIf, email, minLength } from 'vuelidate/lib/validators'
import i18nCountries from '@/utils/mixins/i18nCountries'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'EditUser',
  components: {
    BirthdayField: () => import('@/components/formFields/BirthdayField'),
  },
  mixins: [formUtils, i18nCountries],
  data() {
    return {
      form: {},
      // isNewUser: this.$route.name === 'register',
      secretKey: this.$route.query.secretKey,
      showPassword: false,
    }
  },
  computed: {
    user() { return this.$store.getters['user/data'] },
    isNewUser: ({ $route }) => $route.name === 'register',
  },
  validations() {
    return {
      form: {
        email: { required, email },
        password: { required: requiredIf(() => this.isNewUser), minLength: minLength(8) },
        firstName: { required },
        lastName: { required },
        birthdate: { required },
        country: { required },
      },
    }
  },
  async created() {
    const { isNewUser } = this
    await this.setCountriesLocale(this.$store.state.locale)
    // We do this because an user can be still logged on and binded, and we don't want to log it out
    this.form = {
      email: isNewUser ? this.$route.query.email : this.user.email,
      firstName: isNewUser ? null : this.user.firstName,
      lastName: isNewUser ? null : this.user.lastName,
      birthdate: isNewUser ? null : `${this.user.birthdate.year}-${this.user.birthdate.month}-${this.user.birthdate.day}`,
      country: isNewUser ? null : (this.user.country && this.countries[this.user.country]),
    }
  },
  methods: {
    parseForm() {
      const [year, month, day] = this.form.birthdate.split('-')
      return {
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        birthdate: {
          year: +year,
          month: +month,
          day: +day,
        },
        country: Object.entries(this.countries).find(([, name]) => name === this.form.country)[0],
      }
    },
    createUser() {
      const { form, secretKey, parseForm } = this
      if (!this.isFormValid()) return
      this.runAsync(async () => {
        await this.$store.dispatch('user/create', { email: form.email, password: form.password, secretKey, data: parseForm() })
        this.$router.push({ name: 'redirectAfterLogin' })
      })
    },
    updateUser() {
      if (!this.isFormValid()) return
      this.runAsync(async () => {
        await this.$store.dispatch('user/update', { id: this.user.id, data: this.parseForm() })
        this.$emit('close-dialog')
      })
    },
  },
}
</script>
